<template>
  <section>
    <section v-if="isCorporateCompany" class="mbt-2">
      <h5 class="bold-header-text">Create your application from a template</h5>
      <b-card class="dashboard-card shadow-sm mb-3">
        <p>
          You may enter your application with all of your locations through a an excel template. You
          can also create your application with all locations below. If you choose to use the excel
          template please download the template file below and then upload the completed application
          template through the upload button below.
        </p>
        <div class="space-between">
          <div class="data-block application-button-block">
            <button class="btn-blue" @click="openDownloadTemplateModal">Download Template</button>
            <DownloadTemplateModal ref="DownloadTemplateModal"></DownloadTemplateModal>
          </div>
          <div class="custom-upload-btn-wrapper">
            <button class="btn-blue" :disabled="uploadProcessing">Upload Completed Template</button>
            <input
              type="file"
              title=" "
              name="upload"
              @change="handleFileUpload"
              accept=".xls,.xlsx"
              :disabled="uploadProcessing"
            />
          </div>
        </div>
      </b-card>
      <h5 class="bold-header-text">Uploaded Templates</h5>
      <kendo-grid
        :sortable="false"
        :data-items="applicationUploadedTemplateFiles"
        :columns="tableColumns"
      >
        <template v-slot:dateCreated="{ props }">
          <td>
            {{ formatDate(props.dataItem.dateCreated) }}
          </td>
        </template>
      </kendo-grid>
    </section>
    <div class="mbt-2">
      <p>
        Your application must include your main location and any additional locations on your
        application. Clicking the "Add Location" button will allow you to select additional
        locations for this application. If you have additional locations that aren't displayed in
        the "Add Location" dialog please click the "Manage Locations" link in the left-hand menu
        under "Quick Links" to add locations to your profile.
      </p>
      <h5 v-if="isCorporateCompany" class="bold-header-text">Manually create your application</h5>
    </div>
    <ApplicationLocation
      :applicationID="applicationID"
      :location="parentApplication"
      :isMainLocation="true"
      :applicationCompanyID="applicationCompanyID"
      @callbackFunc="getApplications"
    />
    <div class="space-between">
      <h5 class="bold-header-text">Additional Locations</h5>
      <div class="data-block application-button-block">
        <button @click="handleAddLocation" class="btn-blue">Add Location</button>
      </div>
    </div>
    <ApplicationLocation
      v-for="(location, index) in additionalLocations"
      :applicationID="applicationID"
      :location="location"
      :isMainLocation="false"
      :applicationCompanyID="applicationCompanyID"
      @callbackFunc="getApplications"
      :key="index"
    />
    <ApplicationAvailableLocationsModal ref="ApplicationAvailableLocationsModal" />
  </section>
</template>
<script>
import ApplicationCompanyMixin from "../../../mixins/applicationCompanyMixin.vue";
import { getCurrentCompany } from "../../../utils/customFunctions";
import ApplicationLocation from "./ApplicationLocation.vue";
import ApplicationAvailableLocationsModal from "./ApplicationAvailableLocationsModal.vue";
import UploadDocumentMixin from "../../../mixins/UploadDocumentMixin.vue";
import DownloadTemplateModal from "../../../components/Modals/DownloadTemplateModal.vue";
import { bus } from "../../../main";
import { Grid } from "@progress/kendo-vue-grid";
import moment from "moment";

export default {
  props: ["applicationID"],
  mixins: [ApplicationCompanyMixin, UploadDocumentMixin],
  async created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });

    const { numberOfLocations = 0 } = getCurrentCompany();
    this.isCorporateCompany = numberOfLocations > 10;
    this.getApplications();
    const { companyID = 0 } = getCurrentCompany();
    await this.getApplicationCompanyDetails(this.applicationID, companyID);
    await this.getApplicationUploadedFilesTemplatesList();
    await this.getUploadedDocuments(companyID);
    this.applicationCompanyID = this.applicationCompanyDetail.applicationCompanyID;
    bus.$emit("showFullpageOverlay", {
      showLayover: false,
    });
  },
  data() {
    return {
      parentApplication: null,
      additionalLocations: [],
      isCorporateCompany: false,
      applicationCompanyID: null,
      tableColumns: [
        { title: "File Name", field: "fileName" },
        { title: "Upload Date", cell: "dateCreated" },
      ],
    };
  },
  components: {
    ApplicationLocation,
    ApplicationAvailableLocationsModal,
    DownloadTemplateModal,
    "kendo-grid": Grid,
  },
  methods: {
    openDownloadTemplateModal() {
      this.$refs.DownloadTemplateModal.show({
        title: `Download Template`,
        exitFunction: async () => {},
      });
    },
    async getApplications() {
      const { companyID = 0 } = getCurrentCompany();
      await this.getApplicationCompanyByApplication(this.applicationID);
      if (this.statusCode === 200 && this.applicationCompaniesByApplication.length) {
        this.parentApplication = this.applicationCompaniesByApplication.find(
          (ele) => ele.companyID === companyID
        );
        this.additionalLocations = this.applicationCompaniesByApplication.filter(
          (ele) => ele.companyID !== companyID
        );
      }
    },
    handleAddLocation() {
      const opt = {
        applicationID: this.applicationID,
        applicationCompanyID: this.applicationCompanyID,
        exitFunction: () => this.getApplications(),
      };
      this.$refs.ApplicationAvailableLocationsModal.show(opt);
    },
    async handleFileUpload(e) {
      const selectedFiles = e.target.files;
      if (selectedFiles.length) {
        const { companyID = 0 } = getCurrentCompany();
        let params = { companyID };
        await this.sendFiles(params, selectedFiles[0], false).then(async (result) => {
          if (result.status === 200) {
            await this.getApplicationUploadedFilesTemplatesList();
          }
        });
      }
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("yyyy-MM-DD");
      }
      return "-";
    },
    async getApplicationUploadedFilesTemplatesList() {
      const { companyID = 0 } = getCurrentCompany();
      await this.getApplicationUploadedFilesTemplates(companyID);
    },
  },
};
</script>
<style scoped>
.mbt-2 {
  margin-bottom: 2rem;
}
</style>
