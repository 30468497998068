<template>
  <b-modal
    id="available-locations-modal"
    ref="available-locations-modal"
    title="Add Location"
    size="lg"
    v-model="isVisible"
    :no-close-on-backdrop="true"
    @ok="close()"
    @cancel="close()"
    @hide="close()"
  >
    <messages />
    <Skeleton
      :shape="'rectangle'"
      :style="{
        width: '100%',
        height: '230px',
      }"
      v-if="loading"
    />
    <!-- <Loader theme-color="primary" type="converging-spinner" v-if="loading" /> -->
    <b-alert variant="info" class="rounded" show dismissible v-else-if="!availableLocations.length"
      ><span
        >If you wish to add additional locations please click on Manage Locations under Quick
        Links.</span
      ></b-alert
    >
    <div v-else>
      <kendo-grid
        :sortable="true"
        :sort="sort"
        :data-items="availableLocations"
        :columns="tableColumns"
        @datastatechange="dataStateChange"
      >
        <template v-slot:myTemplate="{ props }">
          <td class="k-command-cell">
            <kbutton
              class="login-blue"
              :icon="'plus'"
              :theme-color="'primary'"
              :fill-mode="'outline'"
              :size="'small'"
              @click="editHandler(props.dataItem.companyID)"
              >Add To Application
            </kbutton>
          </td>
        </template>
        <template v-slot:addressTemplate="{ props }">
          <td class="k-command-cell">
            <address
              class="address-text"
              v-for="(address, index) in companyAddresses(props.dataItem.addressLine1)"
              :key="index"
            >
              {{ address }}
            </address>
          </td>
        </template>
      </kendo-grid>
    </div>
    <template #modal-footer>
      <b-button variant="primary" class="login-blue mr-1" @click="close">Close</b-button>
    </template>
  </b-modal>
</template>
<script>
import ApplicationMixin from "../../../mixins/ApplicationMixin.vue";
import applicationCompanyMixin from "../../../mixins/applicationCompanyMixin.vue";
import { mapMutations } from "vuex";
import ModalMessages from "../../../containers/navs/ModalMessages.vue";
import { Grid } from "@progress/kendo-vue-grid";
import { Button } from "@progress/kendo-vue-buttons";
import { Skeleton } from "@progress/kendo-vue-indicators";

export default {
  data() {
    return {
      isVisible: false,
      tableColumns: [
        {
          cell: "myTemplate",
          title: "Actions",
          width: "270",
        },
        {
          field: "companyLegalName",
          title: "Company",
          width: "140",
        },
        {
          cell: "addressTemplate",
          title: "Address",
          width: "335",
        },
      ],
      sort: [],
      applicationID: null,
      applicationCompanyID: null,
    };
  },
  mixins: [ApplicationMixin, applicationCompanyMixin],
  components: {
    messages: ModalMessages,
    "kendo-grid": Grid,
    kbutton: Button,
    Skeleton,
  },
  async created() {},
  methods: {
    ...mapMutations(["setModalInfoMessage", "setModalDangerMessage"]),
    show(opt) {
      this.setModalInfoMessage("");
      this.applicationID = opt.applicationID;
      this.applicationCompanyID = opt.applicationCompanyID;
      this.getData();
      this.isVisible = true;
      if (opt.exitFunction) {
        this.exitFunction = opt.exitFunction;
      }
    },
    close() {
      this.isVisible = false;
      if (this.exitFunction) this.exitFunction();
    },
    dataStateChange(event) {
      this.sort = event.data.sort;
      this.getData();
    },
    async getData() {
      await this.getAvailableLocations(this.applicationID);
    },
    async editHandler(companyID) {
      const payload = {
        applicationID: this.applicationID,
        companyID,
        applicationCompanyID: this.applicationCompanyID,
      };
      await this.submitApplicationCompany(payload);
      if (this.statusCode === 200) {
        this.getData();
      }
    },
    companyAddresses(addresses) {
      return addresses.split("|");
    },
  },
};
</script>
