<template>
  <section>
    <confirmation ref="Confirmation"></confirmation>
    <div v-if="location">
      <h5 class="bold-header-text">
        {{ isMainLocation ? "Main Company Location |" : "" }}
        {{ location.companyLegalName }}
      </h5>
      <b-card class="dashboard-card shadow-sm mb-3">
        <div class="space-between">
          <div>
            <p class="mb-3">{{ formattedAddress(location) }}</p>
          </div>
          <div>
            <kbutton
              :icon="'trash'"
              :theme-color="'error'"
              :size="'small'"
              @click="handleDelete"
              v-if="!isMainLocation"
            >
            </kbutton>
          </div>
        </div>
        <div>
          <Stepper :value="step" :items="steps" @change="stepperChange" :item="'CustomizedStep'">
            <template v-slot:CustomizedStep="{ props }">
              <Step v-bind="props" @change="(e) => props.onChange(e)">
                <span
                  class="k-step-indicator"
                  aria-hidden="true"
                  :style="displayWarning(props) ? disabledStepStyle : ''"
                >
                  <Tooltip
                    :content="'myTooltipTemplate'"
                    :anchor-element="'target'"
                    :position="'top'"
                    :auto-hide="true"
                  >
                    <span
                      class="k-step-indicator-icon k-icon"
                      :class="props.icon"
                      :title="displayWarning(props)"
                    />
                    <template v-slot:myTooltipTemplate="{ props }">
                      <a>
                        <strong>{{ props.title }}</strong>
                      </a>
                    </template>
                  </Tooltip>
                </span>
                <span class="k-step-label">
                  <span class="k-step-text">{{ props.label }}</span>
                  <span
                    aria-hidden="true"
                    class="k-step-indicator-icon k-icon"
                    :class="props.isValid ? 'k-i-check-circle' : 'k-i-exclamation-circle'"
                  ></span>
                </span>
              </Step>
            </template>
          </Stepper>
        </div>
        <b-row align-h="end" align-content="end">
          <b-col class="mt-2" xs="12" sm="6" md="6" lg="2" xl="2" align-self="end">
            <div class="data-block application-button-block">
              <button @click="handleEdit" class="btn-blue">Edit</button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </section>
</template>
<script>
import { Stepper, Step } from "@progress/kendo-vue-layout";
import { Button } from "@progress/kendo-vue-buttons";
import Confirmation from "../../../components/Common/Confirmation.vue";
import ApplicationCompanyMixin from "../../../mixins/applicationCompanyMixin.vue";
import { routePaths } from "../../../utils/customConstants";
import { Tooltip } from "@progress/kendo-vue-tooltip";

export default {
  mixins: [ApplicationCompanyMixin],
  props: ["applicationID", "location", "applicationCompanyID", "isMainLocation"],
  watch: {
    location: function (newVal) {
      const appSteps = newVal.steps;
      if (newVal && appSteps) {
        this.handleStepStatus(appSteps);
      }
    },
  },
  data() {
    return {
      step: -1,
      steps: [
        {
          label: "About Your Organization",
          icon: "k-i-user",
          isValid: false,
        },
        {
          label: "Contacts",
          icon: "k-i-paste-plain-text",
          isValid: false,
        },
        {
          label: "Program-Specific",
          icon: "k-i-track-changes-accept",
          isValid: false,
        },
        {
          label: "Travel and Logistics",
          icon: "k-i-cart",
          isValid: false,
        },
        {
          label: "Affiliations",
          icon: "k-i-preview",
          isValid: false,
        },
      ],
      allStepsCompleted: false,
      tooltipText: "",
      disabledStepStyle: { backgroundColor: "#74a2bf !important" },
    };
  },
  components: {
    Stepper,
    kbutton: Button,
    Confirmation,
    Step,
    Tooltip,
  },
  computed: {},
  methods: {
    // Display only for last step
    displayWarning({ index, numOfSteps }) {
      return index === numOfSteps - 1 && this.isMainLocation ? this.tooltipText : "";
    },
    stepperChange(event) {
      let allowToMove = true;
      // if last step
      if (this.isMainLocation && event.value === this.steps.length - 1) {
        // prevent if remaining steps are not completed
        if (!this.allStepsCompleted) allowToMove = false;
      }
      allowToMove &&
        this.$router.push({
          name: "ApplicationInfo",
          params: {
            applicationID: this.applicationID,
            step: routePaths[event.value],
            companyID: this.location.companyID,
            applicationCompanyID: this.applicationCompanyID,
          },
        });
    },
    handleEdit() {
      this.$router.push({
        name: "ApplicationInfo",
        params: {
          applicationID: this.applicationID,
          step: routePaths[0],
          companyID: this.location.companyID,
        },
      });
    },
    formattedAddress({ addressLine1 = "", city = "", stateCode = "" }) {
      return [addressLine1, city, stateCode].join(", ");
    },
    handleDelete() {
      this.$refs.Confirmation.show({
        title: "Delete Location",
        message: `Are you sure you want to delete location ${this.location.applicationCompanyID}?`,
        okButton: "Yes",
        cancelButton: "No",
        exitFunction: () => {},
      }).then(async (result) => {
        if (result) {
          this.setInfoMessage("");
          await this.deleteApplicationCompany(this.location.applicationCompanyID).then(() => {
            this.$emit("callbackFunc");
          });
        }
      });
    },
    handleStepStatus(appSteps) {
      if (appSteps.length) {
        // Set Step completed Status
        appSteps
          .filter(({ applicationStepID }) => applicationStepID)
          .forEach(({ applicationStepID, isComplete }) => {
            this.steps[applicationStepID - 1].isValid = isComplete;
          });
        //completed steps count
        let completedStepsCount = appSteps.filter(({ isComplete }) => isComplete).length;
        const lastStep = this.steps.length - 1;
        if (completedStepsCount === lastStep) this.allStepsCompleted = true;
      }
      //Warning for last step tooltip
      const incompletedSteps = this.steps
        .filter(({ isValid }, index) => !isValid && index !== this.steps.length - 1)
        .map(({ label }) => label)
        .join(", ");
      if (incompletedSteps) {
        this.tooltipText = `The application isn't complete.  Please finish filling out the ${incompletedSteps} screens`;
      }
    },
  },
  created() {
    // Deposit step only for main location
    if (this.isMainLocation) {
      this.steps.push({
        label: "Deposit",
        icon: "k-i-dollar",
        isValid: false,
      });
    } else {
      // Handle Step if this is additional
      if (this.location && this.location.steps) {
        this.handleStepStatus(this.location.steps);
      }
    }
  },
};
</script>
