var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"available-locations-modal",attrs:{"id":"available-locations-modal","title":"Add Location","size":"lg","no-close-on-backdrop":true},on:{"ok":function($event){return _vm.close()},"cancel":function($event){return _vm.close()},"hide":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"login-blue mr-1",attrs:{"variant":"primary"},on:{"click":_vm.close}},[_vm._v("Close")])]},proxy:true}]),model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('messages'),(_vm.loading)?_c('Skeleton',{style:({
      width: '100%',
      height: '230px',
    }),attrs:{"shape":'rectangle'}}):(!_vm.availableLocations.length)?_c('b-alert',{staticClass:"rounded",attrs:{"variant":"info","show":"","dismissible":""}},[_c('span',[_vm._v("If you wish to add additional locations please click on Manage Locations under Quick Links.")])]):_c('div',[_c('kendo-grid',{attrs:{"sortable":true,"sort":_vm.sort,"data-items":_vm.availableLocations,"columns":_vm.tableColumns},on:{"datastatechange":_vm.dataStateChange},scopedSlots:_vm._u([{key:"myTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{staticClass:"k-command-cell"},[_c('kbutton',{staticClass:"login-blue",attrs:{"icon":'plus',"theme-color":'primary',"fill-mode":'outline',"size":'small'},on:{"click":function($event){return _vm.editHandler(props.dataItem.companyID)}}},[_vm._v("Add To Application ")])],1)]}},{key:"addressTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{staticClass:"k-command-cell"},_vm._l((_vm.companyAddresses(props.dataItem.addressLine1)),function(address,index){return _c('address',{key:index,staticClass:"address-text"},[_vm._v(" "+_vm._s(address)+" ")])}),0)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }