<template>
  <b-modal
    id="modalbasic"
    size="lg"
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    :title="this.title"
    :hide-footer="true"
    :no-close-on-backdrop="true"
    @ok="close()"
    @cancel="close()"
  >
    <div class="custom-upload-btn-wrapper">
      <b-form>
        <api-button-overlay>
          <div class="file-btns">
            <b-button
              class="login-blue"
              v-for="templateName in templateNames"
              :key="templateName.btnText"
            >
              <a :href="`../../../assets/application-template/${templateName.fileName}`" download
                ><span class="label">Download Template for - {{ templateName.btnText }}</span></a
              >
            </b-button>
          </div>
        </api-button-overlay>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data: () => ({
    title: undefined,
    isVisible: false,
    templateNames: [
      { btnText: "DMEPOS", fileName: "Corporate Demographic Sheet - DMEPOS_ RX.xlsx" },
      { btnText: "RX", fileName: "Corporate Demographic Sheet - DMEPOS_ RX.xlsx" },
      { btnText: "HH", fileName: "Corporate Demographic Sheet - HH_ HSP.xlsx" },
      { btnText: "HSP", fileName: "Corporate Demographic Sheet - HH_ HSP.xlsx" },
      { btnText: "SLP", fileName: "Corporate Demographic Sheet - SLP.xlsx" },
    ],
  }),
  methods: {
    ...mapMutations(["setModalDangerMessage", "setModalInfoMessage", "resetMessages"]),
    async show(opts = {}) {
      this.title = opts.title;
      this.resetMessages();

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction;
      }

      this.isVisible = true;
    },
    close() {
      if (this.exitFunction) {
        this.exitFunction();
      }

      this.setModalDangerMessage("");
      this.setModalInfoMessage("");
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-btns {
  display: flex;
  flex-direction: column;
}

.custom-upload-btn-wrapper {
  width: 100%;
}

.login-blue a {
  color: #ffffff;
  text-decoration: none;
}
</style>
